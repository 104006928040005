import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer"
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import { theme } from "../theme";

import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body, html, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
background-color: ${theme.colors.bg};
  }
  p {
    line-height: 1.4;
   }
  * {
    box-sizing: border-box;
  }
  .slide-show.slick-slide {
    opacity: 1 !important;
  }
  html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0}main{display:block}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}[hidden]{display:none}
`;

const SiteWrap = styled.div`
  height: 100%;
  font-family: ${theme.fonts.secondary};
  position: relative;
`;

export const Main = styled.div`
  max-width: ${props => props.noPadding ? '100%' : 'calc(1440px + 4rem)'};
  margin: 0 auto;
  width: 100%;
  padding: ${props => props.noPadding ? '0' : '1rem 4rem'};
`;

const Header = styled.header`
  width: 100%;
`


const TemplateWrapper = ({ children, noWrap = false, noPadding = true }) => {
  const { title, description } = useSiteMetadata();
  return (
    <SiteWrap>
      <GlobalStyle />
      <Helmet>
        <html lang="en" />
        <link rel="apple-touch-icon" sizes="180x180" href="static/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="static/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="static/favicon-16x16.png"/>
      </Helmet>
        <Header>
          <Navbar />
        </Header>
      <Main noPadding={noPadding}>
        {children}
      </Main>
      <Footer />
    </SiteWrap>
  );
};

export default TemplateWrapper;
