
import React from 'react'
import styled from "styled-components";

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  `
const Footer = styled.footer`
  max-width: calc(1440px + 4rem);
  width: 100%;
  padding: 2rem 4rem;
`

const Divider = styled.div`
  width: 8rem;
  height: 3px;
  background-color: black;
`
const FooterHeading = styled.h4`
  margin-bottom: 1rem;
`;


const TextPostPreview = () => {
  return (
   <Footer>
     <Divider />
     <FooterHeading>Contact: </FooterHeading>
      <p>Represented by <StyledLink href="https://milanigallery.com.au/">Milani Gallery</StyledLink></p>
      <StyledLink href="mailto:pammcgrath@bigpond.com">Email</StyledLink>
      <br/>
      <StyledLink download href="/NIGEL-LENDON-CV-March-2022.pdf" target="_blank">CV</StyledLink>
      <br/>
      <StyledLink href="https://www.instagram.com/iconophiliac/?hl=en">Instagram</StyledLink>
   </Footer>
  )
}


export default TextPostPreview