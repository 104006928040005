import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { theme } from "../theme";

const NavWrap = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.bg};
  text-transform: uppercase;
  max-width: calc(1440px + 4rem);
  width: 100%;
  a {
    text-decoration: none;
    color: #000;
  }
`;

const Name = styled.h2`
  font-size: 2.8rem;
  margin: 0 0 0.5rem;
  padding: 1rem 4rem 0;
  @media (max-width: 768px) {
  font-size: 2rem;
    padding: 2rem 2rem 0;
    width: 100%
  }
`;

const NavItemWrap = styled.div`
  display: flex;
  padding: 0 4rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const NavItem = styled(Link)`
  font-size: 1.6rem;
  padding: 1.5rem 2rem;
  margin: 0.5rem 0;
  transition: 0.2s;
  font-weight: 600;
  &:first-child {
    padding-left: 0;
  }
  &:hover {
    opacity: 0.6;
  }
  @media (max-width: 768px) {
    font-size: 1.4rem;

    &:first-child {
      padding-left: 2rem;
    }
  }
`;

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query NavItems {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { regex: "/page/" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const items = [
    {
      to: '/bio',
      text: 'Bio'
    },
    {
      to: '/works',
      text: 'Works'
    },
    {
      to: '/texts',
      text: 'Texts'
    },
  ]


  return (
    <NavWrap>
      <Name>
        <Link to="/">Nigel Lendon</Link>
      </Name>
      <NavItemWrap>
        {items.map(({to, text}) =>(
        <NavItem
          partiallyActive={true}
          activeStyle={{ borderBottom: "2px solid black" }}
          key={to}
          to={to}
        >
          {text}
        </NavItem>
        ))}
      </NavItemWrap>
    </NavWrap>
  );
};

export default Navbar;
