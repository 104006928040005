import { DefaultTheme } from "./styled";

const colors = {
  bg: "#EFEFEF",
  red: "#D4212A",
  border: "#15140E",
};

const fonts = {
  primary: "Droid Sans, sans-serif",
  secondary: "Work Sans, sans-serif",
};

export const device = {
  mobile: `(min-width: 425px`,
  tablet: `(min-width: 768px)`,
  laptop: `(min-width: 1440px)`,
  desktop: `(min-width: 2200px)`,
};

export const theme: DefaultTheme = {
  borderRadius: "4px",
  colors,
  fonts,
  device,
};
